import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "styled-components";
import { App } from './components/App';
import { GlobalStyles, MainContainer } from './components/Main';

export const theme = {
  fonts: {
    number: "'Nunito', sans-serif",
    text: "'DM Sans', sans-serif",
  },

  media: {
    /** For use in a @media min-width query */
    breakpoints: {
      mobile: "64rem",
    },
    /** For use in CSS min-width properties */
    min: {
      mobile: "20rem",
    },
  },

  green: {
    lightest: "#D4EBE4",
    light: "#05B98C",
    medium: "#1E7B5D",
    dark: "#0C3226",
  },
  yellow: {
    light: "#F1FBDA",
    medium: "#E1FEA1",
    dark: "#46591C",
  },
  blue: {
    lightest: "#E9F3FF",
    light: "#C5DFFF",
    medium: "#6EB0FF",
    dark: "#224875",
  },
  terracotta: {
    light: "#FED5C7",
    medium: "#E67A70",
    dark: "#500837",
  },
  black: {
    light: "#B3B8C2",
    medium: "#667085",
    dark: "#344054",
    darkest: "#101828",
  },
  white: {
    lightest: "#FFFFFF",
    light: "#F5F6F8",
    medium: "#EAECF0",
    dark: "#D0D5DD",
  },
  grey: {
    lightest: "#FCFCFC",
  },

  transparency: {
    "05%": "0d",
    "25%": "40",
    "50%": "80",
    "75%": "C0",
    "95%": "F2",
  },
} as const;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MainContainer>
        <App />
      </MainContainer>
    </ThemeProvider>
);