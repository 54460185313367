export type FormatCurrencyOutput = {
  singleLine: string;
  majorMinor: {
    signAndSymbol: string;
    major: string;
    minor: string;
  };
  number: number;
};

export const formatCurrency = (
  amount: string,
  options: {
    signed: boolean;
  } = {
    signed: true,
  },
): FormatCurrencyOutput => {

  const amountNum = Number(amount);
  const amountSigned = options.signed ? amountNum : Math.abs(amountNum);
  const sign = amountSigned < 0 ? "-" : "";
  const symbol = "$";
  const separator = ".";
  const amountTokens = Math.abs(amountNum)
    .toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .split(separator);

  const integer = amountTokens[0] as string;
  const decimal = amountTokens[1] as string;

  const output = {
    number: amountSigned,
    singleLine: `${sign}${symbol}${integer}${separator}${decimal}`,
    majorMinor: {
      signAndSymbol: `${sign}${symbol}`,
      major: `${integer}`,
      minor: `${separator}${decimal}`,
    },
  };

  return output;
};