import styled from 'styled-components';
import holdingsLogo from '../../images/holdings.png';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.white.lightest};
  border-bottom: .063rem solid ${props => props.theme.white.medium};
  margin: 0;
  height: 5rem;
  width: 100%;
`;

const Logo = styled.img`
  margin-left: 2rem;
  width: 5rem;
`;

const GetStartedButton = styled.a`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.white.lightest};
  background-color: ${(props) => props.theme.black.darkest};
  margin-right: 2rem;
`;

export const StickyHeader = () => {
  return (
    <StyledHeader>
      <Logo src={holdingsLogo} alt="Holdings" />
      <GetStartedButton
        href="https://dashboard.holdings.io/sign-up?referrer=%22https://getholdings.com%22&affiliate=%22yield_calculator%22"
        rel="noreferrer noopener"
      >
        Sign Up
      </GetStartedButton>
    </StyledHeader>
  );
};