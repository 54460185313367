import { ChangeEvent, useState } from "react"
import { AppContainer, CalculateYieldButton, Input, DepositContainer, DepositAmount, YieldCalculatorHeader, YieldEarnedAccounts } from "./styles"
import { AccountType } from "../../calculate"
import { YieldEarned } from "../YieldEarned"
import { StickyHeader } from "../StickyHeader"

export const App = () => {
  const [depositAmount, setDepositAmount] = useState<string | null>(null)
  const [calculatedAmount, setCalculatedAmount] = useState<number>(0)

  const handleCalculateYield = () => {
    let amount = parseFormattedNumber(depositAmount || "0")
    setCalculatedAmount(amount)
  }

  const formatNumberInput = (value: string) => {
    // Remove any non-digit characters except for the decimal point
    let cleanedValue = value.replace(/[^\d.]/g, '');
  
    // Ensure only one decimal point
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      cleanedValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = cleanedValue.split('.');
  
    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Combine the formatted integer part with the decimal part (if it exists)
    let formattedValue = formattedIntegerPart;
    if (decimalPart !== undefined) {
      formattedValue += '.' + decimalPart;
    }
  
    return formattedValue;
  };

  const parseFormattedNumber = (formattedValue: string): number => {
    // Remove commas and any other non-numeric characters except the decimal point
    const cleanedValue = formattedValue.replace(/[^\d.-]/g, '');
    
    // Parse the cleaned string to a float
    const parsedValue = parseFloat(cleanedValue);
    
    // Check if the result is a valid number
    if (isNaN(parsedValue)) {
      return NaN;
    }
    
    return parsedValue;
  };
  
  const handleNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatNumberInput(inputValue);
    
    
    setDepositAmount(formattedValue);
  };

  const accounts: AccountType[] = ["wells", "evolve", "allspring"]

  return (
    <AppContainer>
      <StickyHeader />
      <YieldCalculatorHeader>Holding's Yield Calculator</YieldCalculatorHeader>
      <DepositContainer>
        <DepositAmount>
          Deposit Amount:
        </DepositAmount>
        <Input
          id="deposit-amount"
          type="text"
          placeholder="$0.00"
          value={depositAmount ?? ""}
          onChange={handleNumberInputChange}
        />
        <CalculateYieldButton
          disabled={!depositAmount}
          onClick={handleCalculateYield}
        >
          Update
        </CalculateYieldButton>
      </DepositContainer>
      <YieldEarnedAccounts>
        {accounts.map((account) => (
          <YieldEarned
            depositAmount={calculatedAmount}
            accountType={account}
          />
        ))}
      </YieldEarnedAccounts>
    </AppContainer >
  )
}