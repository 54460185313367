import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    background-color: transparent;
    box-sizing: border-box;
    border: 0;
    display: block;
    font-family: ${(props) => props.theme.fonts.text};
    font-style: normal;
    font-weight: 400;
    // Accessibility: let the browser’s default size
    // (which can be customized by the user to their accessibility needs)
    // serve as a baseline from where we can cascade our own styles
    font-size: unset;
    margin: 0;
    overflow: auto;
    outline: none;
    padding: 0;
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
  }
  body {
    margin: 0;
  }
  button:disabled {
    cursor: not-allowed !important;
  }
  button:hover {
    cursor: pointer;
  }
  head {
    display: none
  }
  table {
    display: table;
  }
  tbody {
    display: table-row-group;
  }
  td {
    display: table-cell;
  }
  th {
    display: table-cell;
  }
  thead {
    display: table-header-group;
  }
  tr {
    display: table-row;
  }
  html {
    // this needs to be set so that the intercom messenger doesn't cause
    // multiple vertical and horizontal scroll bars
    overflow: hidden;
  }
`;

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.white.lightest};
  display: block;
  height: 100vh;
  width: 100vw;

  *::-webkit-scrollbar {
    height: 0.625rem;
    width: 0.3125rem;

    @media (min-width: ${(props) => props.theme.media.breakpoints.mobile}) {
      width: 0.625rem;
    }
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.white.dark};
    border-radius: 0.3125rem;

    &:hover {
      background-color: ${(props) => props.theme.black.light};
    }
    &:active {
      background-color: ${(props) => props.theme.black.medium};
    }
  }
  *::-webkit-scrollbar-corner,
  *::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.white.lightest};
    border-color: ${(props) => props.theme.white.light};
    border-style: solid;
    border-width: 0.0625rem;

    @media (min-width: ${(props) => props.theme.media.breakpoints.mobile}) {
      border-radius: 0.3125rem;
    }
  }
`;
