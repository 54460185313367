import { match } from "ts-pattern";

export type AccountType = "evolve" | "wells" | "allspring";

export const calculateAnnualYield = (depositAmount: number, accountType: AccountType) => {
  let apy = getApyByAccount(accountType);

  return depositAmount * apy;
}

export const getApyByAccount = (accountType: AccountType): number => {
  return match(accountType)
    .with("evolve", () => 0.04)
    .with("wells", () => 0.015)
    .with("allspring", () => 0.05)
    .exhaustive();
}

export const getHumanReadableApy = (accountType: AccountType): string => {
  return match(accountType)
    .with("evolve", () => "4.00%")
    .with("wells", () => "1.50%")
    .with("allspring", () => "5.00%")
    .exhaustive();
}