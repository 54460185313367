import { FunctionComponent } from "react"
import { YieldBreakdown, YieldBreakdownItem, YieldBreakdownLabel, YieldBreakdownValue, YieldEarnedCardTop, YieldEarnedCard, YieldEarnedHeader, YieldEarnedHeaderContainer, YieldRate, YieldRateContainer, YieldEarnedContainer, MonthlyEarningsContainer, MonthlyEarningsAmount, MonthlyEarningsLabel, ApplyNowButton, YieldEarnedDescription } from "./styles"
import { formatCurrency } from "../../format-currency"
import { AccountType, calculateAnnualYield, getHumanReadableApy } from "../../calculate"
import { match } from "ts-pattern"

export const YieldEarned: FunctionComponent<{
  depositAmount: number
  accountType: AccountType
}> = ({
  depositAmount,
  accountType
}) => {

    const getAccountTitle = () => {
      return match(accountType)
        .with("evolve", () => "Sweep Account")
        .with("wells", () => "Cash Account")
        .with("allspring", () => "Money Market Fund")
        .exhaustive();
    }

    const getAccountHeaderColor = () => {
      return match(accountType)
        .with("evolve", () => "#BEB6ED")
        .with("wells", () => "#0B7A75")
        .with("allspring", () => "#CEFCFB")
        .exhaustive();
    }

    const annualYieldEarned = calculateAnnualYield(depositAmount, accountType)
    const monthlyYieldEarned = annualYieldEarned / 12

    return (
      <YieldEarnedContainer>
        <YieldEarnedCardTop
          backgroundColor={getAccountHeaderColor()}
        />
        <YieldEarnedCard>
          <YieldEarnedHeaderContainer>
            <YieldEarnedHeader>
              {getAccountTitle()}
            </YieldEarnedHeader>
            <YieldRateContainer>
              <YieldRate>
                {getHumanReadableApy(accountType)} APY
              </YieldRate>
            </YieldRateContainer>
          </YieldEarnedHeaderContainer>
          <YieldBreakdown>
            <MonthlyEarningsContainer>
              <MonthlyEarningsAmount>
                {formatCurrency(monthlyYieldEarned.toString()).singleLine}
              </MonthlyEarningsAmount>
              <MonthlyEarningsLabel>
                Monthly Earnings
              </MonthlyEarningsLabel>
            </MonthlyEarningsContainer>
            <YieldBreakdownItem>
              <YieldBreakdownValue>
                {formatCurrency(annualYieldEarned.toString()).singleLine}
              </YieldBreakdownValue>
              <YieldBreakdownLabel>
                Yearly Earnings
              </YieldBreakdownLabel>
            </YieldBreakdownItem>
            <YieldEarnedDescription>
              No fees, fully liquid, can be moved same-day any time.
            </YieldEarnedDescription>
            <ApplyNowButton
              href="https://dashboard.holdings.io/sign-up?referrer=%22https://getholdings.com%22&affiliate=%22yield_calculator%22"
              rel="noreferrer noopener"
            >
              Apply Now
            </ApplyNowButton>
          </YieldBreakdown>
        </YieldEarnedCard>
      </YieldEarnedContainer>
    )
  }
