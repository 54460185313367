import styled from "styled-components";
import { PulseLoader } from "react-spinners";

export const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: flex-start;
`;

export const YieldCalculatorHeader = styled.h1`
  color: ${(props) => props.theme.black.dark};
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 3rem;
`;

export const DepositContainer = styled.div`
  color: ${(props) => props.theme.black.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 1rem;
  margin: 2rem 0;

  @media (max-width: ${(props) => props.theme.media.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`;

export const DepositAmount = styled.p`
  color: #344054;
  font-weight: 500;
  line-height: 1.25rem;
`;

export const YieldEarnedAccounts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2rem;
  gap: 2rem;
  width: 100%;
  margin-bottom: 6rem;
`;

export const YieldEarnedActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
`;

export const BackButton = styled.button`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.white.lightest};
  border: ${(props) => props.theme.white.medium} solid 0.0625rem;
  color: ${(props) => props.theme.black.dark};
`;

export const GetStartedButton = styled.a`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  padding: .75rem 1.25rem;
  color: ${(props) => props.theme.white.lightest};
  background-color: ${(props) => props.theme.green.medium};
`;

export const CalculateYieldButton = styled.button`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.white.lightest};
  background-color: ${(props) => props.theme.black.darkest};
`;

export const CalculatingYield = styled(PulseLoader).attrs((props) => ({
  color: props.theme.white.lightest,
  size: 8,
}))``;

export const Input = styled.input.withConfig({
  shouldForwardProp: (prop) => !["error"].includes(prop),
}) <{
  error?: boolean;
}>`
  background-color: ${(props) => props.theme.white.lightest};
  border-color: ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium
      : props.theme.white.dark};
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: 0 0.0625rem
    ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium + props.theme.transparency["50%"]
      : props.theme.white.dark + props.theme.transparency["50%"]};
  color: ${(props) => props.theme.black.dark};
  height: 2.875rem;
  outline: none;
  padding: 0 1rem;
  width: 9.25rem;

  &:focus {
    border-color: ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium
      : props.theme.green.light};
    box-shadow: 0 0.0625rem
      ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium + props.theme.transparency["50%"]
      : props.theme.green.light + props.theme.transparency["50%"]};
    outline: none;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: ${(props) => props.theme.black.medium};
    opacity: 1;
  }
`;