import styled from "styled-components";

export const YieldEarnedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const YieldEarnedCardTop = styled.div<{
  backgroundColor: string;
}>`
    width: 18.5rem;
    height: 1.125rem;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 1.25rem 1.25rem 0 0;
`;

export const YieldEarnedCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 2.5rem;
  width: 18.5rem;
  height: 22.125rem;
  border-radius: 0 0 1.25rem 1.25rem;
  background-color: ${(props) => props.theme.white.lightest};
  border: 0.0625rem solid ${(props) => props.theme.white.medium};
  box-shadow: 0 0.063rem 0.5rem 0 ${(props) => props.theme.white.medium};
`;

export const YieldEarnedHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: .5rem;
`;

export const YieldEarnedHeader = styled.h2`
  color: ${(props) => props.theme.black.dark};
  font-size: .85rem;
  font-weight: 500;
`;

export const YieldBreakdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
`;

export const MonthlyEarningsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const MonthlyEarningsLabel = styled.p`
  color: ${(props) => props.theme.black.medium};
  font-weight: 500;
  border-bottom: 0.063rem solid ${(props) => props.theme.white.medium};
  font-size: .85rem;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
`;

export const MonthlyEarningsAmount = styled.p`
  color: ${(props) => props.theme.black.dark};
  font-family: ${(props) => props.theme.fonts.number};
  font-size: 2.5rem;
`;

export const YieldBreakdownItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const YieldBreakdownLabel = styled.p`
  color: ${(props) => props.theme.black.medium};
  font-weight: 500;
  font-size: .85rem;
`;

export const YieldBreakdownValue = styled.p`
  color: ${(props) => props.theme.black.dark};
  font-family: ${(props) => props.theme.fonts.number};
  font-weight: 700;
`

export const YieldEarnedDescription = styled.p`
  color: ${(props) => props.theme.black.medium};
  font-size: .85rem;
  text-align: center;
`;

export const YieldRateContainer = styled.div`
  align-items: center;
  border: 0.063rem solid ${(props) => props.theme.white.medium};
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  padding: .25rem 0.5rem;
  overflow: hidden;
`;

export const YieldRate = styled.div`
  color: ${(props) => props.theme.black.dark};
  font-family: ${(props) => props.theme.fonts.number};
  overflow: hidden;
`;

export const ApplyNowButton = styled.a`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  border: 0.0625rem solid ${(props) => props.theme.black.dark};
  color: ${(props) => props.theme.black.dark};
`;